
.dropdown_after::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.border_es{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.mr-search{
    margin-left: -5px;
}

.dropdown-menu-end.hlo[style]   {
    left: 0px !important;
    right: 0 !important;
}

