.item.dragging { 
    opacity: 0.6; 
} 
  
.item.dragging .details, 
.item.dragging i { 
    opacity: 0.8; 
    transform: scale(1.02); 
    background: #f0f0f0; 
}

.item .details { 
    display: flex; 
    align-items: center; 
} 
  
.item .details img { 
    height: 60px; 
    width: 60px; 
    pointer-events: none; 
    margin-right: 15px; 
    object-fit: cover; 
    border-radius: 50%; 
} 
  
.item .details span { 
    font-size: 1rem; 
    font-weight: 500; 
    color: #333; 
} 
  
.item i { 
    color: #474747; 
    font-size: 1.13rem; 
} 