.MuiChipsInput-TextField{
    width: 100%;
}
.flag-select ul{
    width: 100%;
}
.flag-select__btn{
    width: 100%;
    text-align: left;
    padding-left: 0px !important;
}
.flag-select__option--placeholder{
    display: inline;
    padding-left: 0px !important;
    font-size: 0.8125rem !important;
}

.flag-select__option__label {
    font-size: 12px !important;
    position: relative;
    /* padding-left: 9px; */
}
.flag-select__options{
    left: 0px !important;
}
.flag-select__btn:after{
    display: none !important;
}