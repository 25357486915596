.explore_checkbox {
    height: 21px !important;
    width: 40px !important;

}

.text-setting {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px !important;
}