.dragdrop-container{
    align-items: center;
    background-color: hsla(240,5%,96%,.67);
    border: 2px dashed #b1a9b945;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
}
.drag-n-drop-container{
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 50px 30px;
}

.sub-category-select #rating{
    height: 36px;
    width: 220px;
    background: white;
    border: 1px solid #ced4da;
    border-radius: 5px;
    color: #747474;
    padding: 0px 12px;
}
.fw-bold{
    text-align: left;
}
.commonImgs{
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 20px 30px;
}
.commonImgs img{
    height: 149px;
    width: 300px;
    object-fit: contain;

}