.ghLWkf div:first-child{
    white-space: unset !important;
    overflow: none !important;
}
.titlelisting img{
    height: 80px;
}
div[data-column-id="3"]{
    min-width: 145px !important;
}
/* .rdt_TableCol_Sortable{
    justify-content: center !important;
} */