// 
// Page-title
// 

.page-title-box {
    padding-bottom: $grid-gutter-width;

    .breadcrumb {
        background-color: transparent;
        padding: 0;
    }

    h4 {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 16px !important;
    }
}
.text-warning{
    color: rgb(241, 203, 33);
}
.text-danger {
    color: rgb(187, 8, 8);
}